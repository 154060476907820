import React from "react";

const ExternalLink = ({ href, children, ...props }) => {
  const normalizedHref = href.startsWith("http") ? href : `https://${href}`;

  return (
    <a
      href={normalizedHref}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
