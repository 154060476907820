import React, { useContext } from "react";
import $ from "jquery";
import { Carousel } from "react-responsive-carousel";
import InitialDataContext from "../../context/initialData/initialDataContext";
import DetailContext from "../../context/detail/detailContext";
import RelatedContent from "../related_content/RelatedContent";
import ArrowLeft from "../../icons/arrow-left.svg";
import ExternalLink from "../external_link";
import NewsThumb from "../news_thumb";
import "./styles.css";

const MarkerDetail = () => {
  const detailContext = useContext(DetailContext);
  const { marker_data, posts } = detailContext;
  const initialDataContext = useContext(InitialDataContext);
  const { getCategorieParent } = initialDataContext;

  const hideDetail = () => {
    if (window.innerWidth <= 500) {
      $(".detail_pol").animate({ height: "hide" });
    } else {
      $(".detail_pol").animate({ width: "hide" });
    }
  };

  const excludedKeys = [
    "categories",
    "description",
    "color",
    "images",
    "posts",
    "direccion",
    "telefono",
    "titular",
    "bloque",
    "operador",
    "titular",
    "norma-legal",
    "tipo-area",
    "link",
  ];

  const data_rc = Object.entries(marker_data.properties)
    .filter(([key]) => !excludedKeys.includes(key))
    .map(([key, value]) => {
      const [video_image, link_poster] = value.split(",");
      return {
        acf_fc_layout: "",
        title: key,
        video_image: video_image || "",
        poster: link_poster || "",
      };
    });

  return (
    <div className="detail_pol">
      <button className="detail_pol__btn" onClick={() => hideDetail()}>
        <img alt="arrow-left" width={15} height={15} src={ArrowLeft} />
      </button>
      {/* CATEGORIA DE CONCESION */}
      {getCategorieParent(marker_data.properties?.categories) !== null ? (
        <div className="detail__pol__breadcrum">
          <p>
            SERVICIOS/
            <strong>
              {`${getCategorieParent(marker_data.properties?.categories)}`}
            </strong>
          </p>
        </div>
      ) : null}
      {/* CARROUSEL DE IMAGENES */}
      {!marker_data.properties.images.includes("") && (
        <div className="detail__carrousel">
          <Carousel
            showThumbs={false}
            showStatus={false}
            className="detail__carrousel__carrousel"
          >
            {marker_data.properties.images.map((img, i) => (
              <img alt={i} width="auto" height="180px" src={img} key={i} />
            ))}
          </Carousel>
        </div>
      )}
      {/* TITULO */}
      {marker_data.title && (
        <div className="detail__pol__info__header">
          <h2>{marker_data.title.toUpperCase()}</h2>
        </div>
      )}
      <div className="detail__info">
        <div className="detail__info__main">
          {/* DIRECCION */}
          {marker_data.properties.direccion !== "" && (
            <p>
              Dirección: <strong>{marker_data.properties.direccion}</strong>
            </p>
          )}
          {/* TELEFONO */}
          {marker_data.properties.telefono !== "" && (
            <p>
              Teléfono: <strong>{marker_data.properties.telefono}</strong>
            </p>
          )}
        </div>
      </div>
      {/* DESCRIPCION */}
      {marker_data.properties.description && (
        <div className="detail__info__main__description">
          {marker_data.properties.description}
        </div>
      )}
      {/* CONTENIDO RELACIONADO */}
      {data_rc.length > 0 && <RelatedContent data={data_rc} />}
      {posts ? (
        <div className="detail_pol__patrocinado">
          <h2>NOTAS RELACIONADAS</h2>
          <Carousel showThumbs={false} showStatus={false} showArrows={false}>
            {posts.map((post, key) => (
              <NewsThumb post={post} key={key} />
            ))}
          </Carousel>
          <hr />
        </div>
      ) : null}
      {/* LINK A SITIO DE EMPRESA */}
      {marker_data.properties.link !== "" ? (
        <div className="detail__info__align__center">
          <ExternalLink
            href={marker_data.properties.link}
            className="detail__btn"
          >
            Visitar sitio
          </ExternalLink>
        </div>
      ) : null}
    </div>
  );
};

export default MarkerDetail;
