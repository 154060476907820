import React from "react";
import placeholder from "../../images/placeholder.png";

const NewsThumb = ({ post }) => {
  return (
    <div className="detail_pol__patrocinado__thumb">
      <a
        className="detail_pol__patrocinado__thumb__img"
        target="_blank"
        href={post.link}
        rel="noreferrer"
      >
        <img
          alt={post.title.rendered}
          width={90}
          height={65}
          src={
            post.images["news--56-00-small"] !== false
              ? post.images["news--56-00-small"]
              : placeholder
          }
        />
      </a>
      <a
        className="detail_pol__patrocinado__thumb__link"
        target="_blank"
        href={post.link}
        rel="noreferrer"
      >
        {post.title.rendered}
      </a>
    </div>
  );
};

export default NewsThumb;
